import $ from 'jquery';
import 'focus-visible';
import config from '@frontend/util/config';
import initAlerts from '@frontend/alerts';
import initAccordions from '@frontend/accordions';
import initEvents from '@frontend/events';
import initLinks from '@frontend/links';
import initTooltips from '@frontend/tooltip';
import initCopyrightYear from '@frontend/copyright';
import initHeroes from '@frontend/heroes';
import initTables from '@frontend/tables';
import initFeatureTopics from '@frontend/feature-topics-grid';
import initNavigation from '@frontend/navigation';
import initShareButtons from '@frontend/social';
import initForms from '@frontend/forms';
import initModal from '@frontend/modal';
import initTabs from '@frontend/tabs';
import initBackToTop from '@frontend/back-to-top';
import initTools from '@frontend/tools';
import initCb from '@frontend/content-blocks';
import initFormEloqua from '@frontend/form-eloqua';
import initCookieBanner from './cookie-banner';
import initDataLayer from './dataLayer';

var initFrontend = function initFrontend(err) {
  if (err && err instanceof Error) {
    console.error(err);
  }

  initCookieBanner('en');
  initDataLayer();
  initEvents();
  initLinks();
  initTables();
  initNavigation();
  initShareButtons();
  initAlerts();
  initAccordions();
  initTooltips();
  initCopyrightYear();
  initHeroes();
  initFeatureTopics();
  initForms();
  initModal();
  initBackToTop();
  initTools();
  initTabs();
  initCb();
  initFormEloqua();
};
/* eslint-disable no-unused-vars */
// This function can be imported into a project to bring in all cigna-frontend js functionality


var loadFrontend = function loadFrontend() {
  var initFunc = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initFrontend;
  if (!window.$) window.$ = jQuery;
  if (!window.jQuery) window.jQuery = jQuery;

  if (config.browser.basic) {
    if (!config.browser.enhanced) {
      // 'core-js/fn/promise' must be part of your "app" entry point or the first line  of app code
      // we are eating the cost of always polyfilling promise so that we can leverage webpack's
      // dynamic import syntax that requires promises.
      import(
      /* webpackChunkName: "polyfill" */
      '@frontend/polyfill').then(function (module) {
        return initFunc();
      }).catch(function (error) {
        return console.warn('Browser not supported, error loading polyfills');
      });
    } else {
      initFunc();
    }
  } else {
    console.warn('Browser not supported');
  }
};
/* eslint-enable */


$(function () {
  loadFrontend(initFrontend);
});