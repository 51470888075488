var contactEmailClick = function contactEmailClick() {
  var contact = document.querySelector("a[href='mailto:cigna.belgium@cigna.com']");
  contact.addEventListener('click', function () {
    window.dataLayer.push({
      event: 'contactEmailClick',
      interactionLocation: 'promo'
    });
  });
  console.log(dataLayer); // eslint-disable-line
};

var contactRequestClick = function contactRequestClick() {
  var contact = document.querySelector("a[href='/contact-us.html']");
  contact.addEventListener('click', function () {
    window.dataLayer.push({
      event: 'contactRequestClick',
      interactionLocation: 'promo'
    });
  });
  console.log(dataLayer); // eslint-disable-line
};

var newsroomcareers = function newsroomcareers() {
  var newsroom = document.querySelectorAll('.nav-link.small-text.link-external');
  [].forEach.call(newsroom, function (item) {
    item.addEventListener('click', function () {
      window.dataLayer.push({
        event: 'outboundLinkClick',
        interactionLocation: 'Header navigation'
      });
    });
  });
  console.log(dataLayer); // eslint-disable-line
};

var financialinformation = function financialinformation() {
  var financialDocs = document.querySelectorAll('td a');
  [].forEach.call(financialDocs, function (item) {
    item.addEventListener('click', function () {
      window.dataLayer.push({
        event: 'docDownload'
      });
    });
  });
  console.log(dataLayer); // eslint-disable-line
};

var init = function init() {
  var url = location.pathname.split('/');

  switch (url[1]) {
    case 'contact-us':
      contactEmailClick();
      newsroomcareers();
      break;

    case 'what-we-offer-europe':
      contactEmailClick();
      newsroomcareers();
      break;

    case 'financial-information':
      financialinformation();
      newsroomcareers();
      break;

    case '':
      contactRequestClick();
      newsroomcareers();
      break;

    default:
      newsroomcareers();
  }
};

export default init;