import $ from 'jquery';
import { cookie } from '@frontend/util/storage';
var COOKIE_NAME = 'cookie_consent';

var init = function init(lang) {
  var cookieName = ''.concat(COOKIE_NAME, '_').concat(lang);
  var haveConsent = Boolean(cookie.get(cookieName)); // Page load - banner hidden by default

  if (!haveConsent) {
    $('.message-cookie-banner').show();
  } // Banner consent


  $('.btn--give-consent').on('click', function () {
    cookie.set(cookieName, 1, {
      expires: 540
    });
    $('.message-cookie-banner').hide();
  });
};

export default init;